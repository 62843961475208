var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-list-grid"},[(!_vm.hideFilters)?_c('ProductFilters',{attrs:{"promoFilter":_vm.promoFilter,"newFilter":_vm.newFilter,"sortFilter":_vm.sortFilter,"facets":_vm.facets,"facetsFilter":_vm.facetsFilter,"filteredProductsCount":_vm.filteredProductsCount,"hidePromoFilter":_vm.hidePromoFilter,"hideFilterString":_vm.hideFilterString,"hideSort":_vm.hideSort,"totItems":_vm.totItems},on:{"handlePromoFilter":_vm.handlePromoFilter,"handleNewFilter":_vm.handleNewFilter,"handleFacetFilter":_vm.handleFacetFilter,"handleFacetFilterMobile":_vm.handleFacetFilterMobile,"handleSortFilter":_vm.handleSortFilter}}):_vm._e(),(_vm.totItems > 0)?[_c('div',{staticStyle:{"max-height":"100%"}},[_c('ProductGrid',{key:_vm.key,attrs:{"productList":_vm.products,"analiticsIndexPosition":(_vm.pager.selPage - 1) * _vm.pager.itemsPerPage,"virtualScrollViewport":_vm.virtualScrollViewport},on:{"selectProduct":_vm.productClicked}})],1),(
        (!_vm.loading || !_vm.virtualScrollViewport) &&
          _vm.pager &&
          _vm.pager.totPages > 1 &&
          !_vm.showLoadMoreItemBtn
      )?_c('v-pagination',{staticClass:"py-6",attrs:{"value":_vm.calcualtedPaginationPage,"length":_vm.calcualtedTotPages,"totalVisible":_vm.$vuetify.breakpoint.xs ? 6 : 7,"prev-icon":"$chevronLeft","next-icon":"$chevronRight","color":"primary","circle":""},on:{"next":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handlePageFilter.apply(null, arguments)},"prev":_vm.handlePageFilter,"input":_vm.handlePageFilter}}):(
        !_vm.loading &&
          _vm.virtualScrollViewport &&
          _vm.hasMoreItems &&
          _vm.showLoadMoreItemBtn
      )?_c('v-btn',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:(_vm.onIntersect),expression:"onIntersect",modifiers:{"quiet":true}}],staticClass:"mt-4",attrs:{"block":"","depressed":"","x-large":""},on:{"click":_vm.loadMore}},[[_vm._v(_vm._s(_vm.$t("products.showMore")))]],2):(_vm.loading)?_c('v-btn',{staticClass:"mt-4",attrs:{"depressed":"","block":"","loading":"","x-large":""}}):_vm._e()]:(_vm.loading)?_c('div',{staticClass:"product-list-grid-skeleton"},[_c('v-row',_vm._l((12),function(index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"6","lg":"3","xl":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"image, list-item-three-line"}})],1)}),1)],1):(_vm.promoFilter)?_c('h2',{staticClass:"py-8 default--text text-center"},[_vm._v(" Nessun prodotto in promozione ")]):(_vm.newFilter)?_c('h2',{staticClass:"py-8 default--text text-center"},[_vm._v(" Nessuna novità qui ")]):_c('h2',{staticClass:"py-8 default--text text-center"},[_vm._v(" Nessun prodotto trovato ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }