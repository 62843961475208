<template>
  <v-container class="product-facet-filter-mobile-container">
    <div class="d-flex justify-space-between align-center">
      <div class="font-weight-bold">{{ $tc("filter.products", totItems) }}</div>
      <div>
        <v-btn
          class="filterBtn"
          color="default"
          block
          height="40"
          outlined
          @click="dialog = true"
        >
          <v-icon class="mr-2">$info</v-icon>
          <span class="text-body-2">
            {{ $tc("filter.filtersAndSort", getFacetsLength) }}</span
          >
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      hide-overlay
      fullscreen
      class="facet-dialog"
    >
      <v-row class="top-bar d-flex justify-end align-center">
        <v-btn plain small @click="discard()" class="my-2">
          <span>{{ $t("filter.button.discard") }}</span>
          <v-icon
            class="ml-1 filter-close"
            :title="$t('common.close')"
            :aria-label="$t('common.close')"
            >$close</v-icon
          >
        </v-btn>
      </v-row>
      <div class="list mt-3">
        <template v-if="!hidePromoFilter">
          <div
            class="d-flex pl-10 mb-2 filter-row promo-filter justify-space-between align-center"
          >
            <span class="font-weight-bold primary--text">{{
              $t("filter.promo")
            }}</span>
            <v-switch
              :input-value="promoFilter"
              inset
              dense
              @change="handlePromoFilter"
              hide-details
              class="my-0"
              color="primary"
            >
            </v-switch>
          </div>
          <div
            class="d-flex pl-10 mb-2 filter-row offerte-filter justify-space-between align-center"
          >
            <span class="font-weight-bold primary--text">{{
              $t("filter.new")
            }}</span>
            <v-switch
              :input-value="newFilter"
              inset
              dense
              @change="handleNewFilter"
              hide-details
              class="my-0"
              color="accent"
            >
            </v-switch>
          </div>
        </template>

        <v-expansion-panels multiple flat v-model="panel">
          <v-expansion-panel v-for="facet in facetsFiltered" :key="facet.name">
            <v-expansion-panel-header class="pl-10">
              <span class="text-body-2 font-weight-bold">{{ facet.name }}</span>
              <div
                class="ml-2 text-caption"
                v-if="
                  facetsFilter[facet.parameterName] &&
                    facetsFilter[facet.parameterName].length > 0
                "
              >
                {{
                  $tc(
                    "filter.filters",
                    facetsFilter[facet.parameterName].length
                  )
                }}
              </div>
              <template v-slot:actions>
                <v-icon>
                  $chevronDown
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters class="d-flex flex-wrap">
                <v-checkbox
                  class="pr-2 w-50 d-flex align-center"
                  multiple
                  hide-details
                  v-for="value in facet.values"
                  :key="value.id"
                  :value="value.id"
                  :disabled="value.filteredCount == 0"
                  v-model="selectedFacetsXS[facet.parameterName]"
                  :label="value.name + ' (' + value.filteredCount + ')'"
                  @click="handleFacetFilterMobile()"
                ></v-checkbox>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row no-gutters class="d-flex pl-9 pr-6 py-3 mb-2" align="center">
          <ProductSortFilter
            :sortFilter="sortFilter"
            @handleSortFilter="handleSortFilter"
        /></v-row>
      </div>
      <v-row
        no-gutters
        class="pa-1 bottom-bar flex-column flex-sm-row"
        align="start"
        justify="space-around"
      >
        <v-col cols="12" sm="5" class="mb-3"
          ><v-btn
            class="w-100"
            color="primary"
            v-on:click="applyFilters"
            depressed
            :loading="loadingApplyButton"
          >
            {{
              $tc("filter.button.applyShowAllProducts", filteredProductsCount)
            }}
          </v-btn></v-col
        >
        <v-col cols="12" sm="5"
          ><v-btn
            v-on:click="clearAllFilters"
            depressed
            class="filter-button w-100"
          >
            {{ $t("filter.button.reset") }}
          </v-btn></v-col
        >
      </v-row>
    </v-dialog>
  </v-container>
</template>

<style scoped lang="scss">
.product-facet-filter-mobile-container {
  .filterBtn {
    border-color: var(--v-grey-base);
    .v-btn__content {
      justify-content: start;
    }
  }
  $buttons-row-height: 80px;
  .facet-dialog {
    .top-bar {
      min-height: $buttons-row-height !important;
      .discard {
        font-size: 0.9rem !important;
      }
      .filter-close {
        font-size: 18px !important;
      }
    }
    .bottom-bar {
      height: $buttons-row-height;
      position: absolute;
      bottom: 0;
    }
    .list {
      height: calc(100vh - (#{$buttons-row-height} * 2));
      overflow-y: auto;
      .filter-row {
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          line-height: 3;
        }
      }
    }
    .v-expansion-panel-content__wrap {
      padding: 0 40px 16px;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter";
export default {
  name: "ProductFacetFilterMobile",
  components: {
    ProductSortFilter
  },
  data() {
    return {
      dialog: false,
      panel: [],
      loadingApplyButton: false,
      initialFacets: {}
    };
  },
  props: {
    facets: {
      type: Array,
      default: () => {
        return [];
      }
    },
    facetsFilter: { type: Object },
    filteredProductsCount: { type: Number, required: false },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, default: "" },
    sortFilter: { type: String },
    totItems: { type: Number }
  },
  computed: {
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter"
    }),
    // promoFilter: {
    //   get() {
    //     return this.storedPromoFilter;
    //   },
    //   set(promo) {
    //     return promo;
    //   }
    // },
    // newFilter: {
    //   get() {
    //     return this.storedNewFilter;
    //   },
    //   set(isNew) {
    //     return isNew;
    //   }
    // },
    getFacetsLength() {
      // const reducedFacets = Object.keys(this.selectedFacetsXS).reduce
      let facetsLength = 0;
      for (const prop in this.selectedFacetsXS) {
        facetsLength += this.selectedFacetsXS[prop].length;
      }
      return facetsLength;
    },
    selectedFacetsXS() {
      return cloneDeep(this.facetsFilter);
    },
    facetsFiltered() {
      let vm = this;
      return vm.facets.filter(
        item => vm.hideFilterString.indexOf(item.name) < 0
      );
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    },
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
      this.loadingApplyButton = true;
    },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
      this.loadingApplyButton = true;
    },
    handleFacetFilterMobile() {
      this.$emit("handleFacetFilterMobile", this.selectedFacetsXS);
      this.loadingApplyButton = true;
    },
    applyFilters() {
      // memorizing last filters applied for future use in case of discard()
      this.initialFacets = cloneDeep(this.selectedFacetsXS);
      this.dialog = false;
      this.panel = [];
    },
    clearAllFilters() {
      this.$emit("handleFacetFilterMobile", {});
      this.initialFacets = {};
      this.dialog = false;
      this.panel = [];
    },
    discard() {
      this.$emit("handleFacetFilterMobile", this.initialFacets);
      this.dialog = false;
      this.panel = [];
    }
  },
  watch: {
    // retrieves filtered products quantity and sets text for Apply Button
    filteredProductsCount(val, oldVal) {
      if (val != oldVal) {
        this.loadingApplyButton = false;
      }
    }
  }
};
</script>
