<template>
  <v-row>
    <v-col cols="6" class="px-1">
      <v-checkbox
        :input-value="newFilter"
        @change="handleNewFilter"
        class="mt-0 py-2 promo-checkbox-wrap"
        hide-details
      >
        <template v-slot:label>
          <v-chip class="ma-2 text-uppercase black--text" color="accent">
            {{ $t("filter.new") }}
          </v-chip>
        </template></v-checkbox
      >
    </v-col>
    <v-col cols="6" class="px-1">
      <v-checkbox
        :input-value="promoFilter"
        @change="handlePromoFilter"
        class="mt-0 py-2 promo-checkbox-wrap"
        hide-details
      >
        <template v-slot:label>
          <v-chip class="ma-2 text-uppercase" color="transparent">
            {{ $t("filter.promo") }}
          </v-chip>
        </template></v-checkbox
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ProductPromoFilter",
  props: ["promoFilter", "newFilter"],
  methods: {
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
    }
  }
};
</script>

<style scoped lang="scss">
.promo-checkbox-wrap {
  &::v-deep .v-input__slot {
    border: 1px solid #d5d5d5;
    padding: 1px 6px 1px 12px;
    max-width: fit-content;
    border-radius: 8px;
  }
  &::v-deep .v-chip {
    height: 30px;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
