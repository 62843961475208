<template>
  <v-row
    no-gutters
    align="center"
    class="data-manipulation-wrapper justify-sm-space-between"
  >
    <!-- <v-col cols="auto" v-if="!isMobile">
      <ProductPromoFilter
        v-if="!hidePromoFilter"
        :promoFilter="promoFilter"
        @handlePromoFilter="handlePromoFilter"
        @handleNewFilter="handleNewFilter"
      />
    </v-col> -->

    <template v-if="facetsFilter">
      <v-col :cols="12">
        <ProductFacetFilterMobile
          v-if="isMobile"
          class="pa-0"
          :facets="facets"
          :facetsFilter="facetsFilter"
          :filteredProductsCount="filteredProductsCount"
          :hidePromoFilter="hidePromoFilter"
          :hideFilterString="hideFilterString"
          :sortFilter="sortFilter"
          :totItems="totItems"
          @handleFacetFilterMobile="handleFacetFilterMobile"
          @handlePromoFilter="handlePromoFilter"
          @handleNewFilter="handleNewFilter"
          @handleSortFilter="handleSortFilter"
        />

        <ProductFacetFilterDesktop
          v-else
          :facets="facets"
          :facetsFilter="facetsFilter"
          :hideFilterString="hideFilterString"
          :filteredProductsCount="filteredProductsCount"
          :productsQty="productsQty"
          :hidePromoFilter="hidePromoFilter"
          :promoFilter="promoFilter"
          :totItems="totItems"
          :sortFilter="sortFilter"
          @handleFacetFilter="handleFacetFilter"
          @handlePromoFilter="handlePromoFilter"
          @handleNewFilter="handleNewFilter"
          @handleSortFilter="handleSortFilter"
        />
      </v-col>
    </template>

    <!-- <v-spacer></v-spacer> -->
    <!-- <v-col :cols="12" sm="3" class="pl-sm-3 d-none d-md-inline">
      <ProductSortFilter
        v-if="!hideSortFilter"
        :sortFilter="sortFilter"
        @handleSortFilter="handleSortFilter"
      />
    </v-col> -->
  </v-row>
</template>

<script>
import ProductFacetFilterMobile from "@/components/product/filters/ProductFacetFilterMobile.vue";
//import ProductSortFilter from "@/components/product/filters/ProductSortFilter";
import ProductFacetFilterDesktop from "@/components/product/filters/ProductFacetFilterDesktop.vue";
//import ProductPromoFilter from "@/components/product/filters/ProductPromoFilter";
export default {
  name: "ProductFilters",
  components: {
    ProductFacetFilterMobile,
    ProductFacetFilterDesktop
    //ProductPromoFilter
    //ProductSortFilter
  },
  props: {
    promoFilter: { type: Boolean },
    facets: { type: Array },
    facetsFilter: { type: Object },
    sortFilter: { type: String },
    filteredProductsCount: { type: Number },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSortFilter: { type: Boolean, default: false },
    totItems: { type: Number },
    productsQty: { type: Number }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    },
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
    },
    handleFacetFilter(filters) {
      this.$emit("handleFacetFilter", filters);
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$emit("handleFacetFilterMobile", selectedFacetsXS);
    }
  }
};
</script>

<style lang="scss">
.data-manipulation-wrapper {
  @media (max-width: 599px) {
    gap: 20px;
  }
}
</style>
