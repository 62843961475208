<template>
  <div class="product-grid mt-2 mt-md-0">
    <h2 v-html="title" v-if="title"></h2>
    <v-row>
      <!-- intersect is here and not on product card because this col is always visible so it work well with browser back and with super fast scroll -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
        v-for="(product, index) in products"
        :key="product.productId"
        class="product-col"
        :id="index"
        :visibilityIndex="index"
      >
        <!-- v-intersect.quiet="updateIndexProductCardsViewable" -->
        <!-- <template
          v-if="
            !virtualScrollViewport ||
              (virtualScrollViewport &&
                index >= visibilityIndex - 10 &&
                index <= visibilityIndex + 10)
          "
        > -->
        <ProductCard
          v-bind:product="product"
          :position="analiticsIndexPosition + index"
          @selectProduct="
            $emit('selectProduct', {
              product: product,
              index: analiticsIndexPosition + index
            })
          "
        />
        <!-- </template>
        <div v-else class="skeleton-product-card"></div> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProductCard from "./ProductCard.vue";

export default {
  components: { ProductCard },
  name: "ProductGrid",
  props: {
    productList: { type: Object, required: false },
    title: { type: String, required: false },
    analiticsIndexPosition: { type: Number, default: 0 },
    virtualScrollViewport: { type: Boolean, default: false }
  },
  data() {
    return {
      products: [],
      visibilityIndex: 1
    };
  },
  computed: {},
  methods: {
    // updateIndexProductCardsViewable(entries) {
    //   try {
    //     if (
    //       entries &&
    //       entries.length > 0 &&
    //       entries[0].isIntersecting == true
    //     ) {
    //       this.visibilityIndex = get(
    //         entries,
    //         "[0].target.attributes['visibilityIndex'].value",
    //         this.visibilityIndex
    //       );
    //       this.visibilityIndex = parseInt(this.visibilityIndex);
    //     }
    //   } catch (err) {
    //     console.log("updating visibility index", err);
    //   }
    // },
    async fetchProducts() {
      if (this.productList && this.productList.products) {
        this.products = this.productList.products;
      }
    }
  },
  created() {
    this.fetchProducts();
  },
  watch: {
    productList(newProductList) {
      if (newProductList?.products?.length > 0) {
        if (newProductList.append) {
          this.products = [...this.products, ...newProductList.products];
        } else {
          this.products = newProductList.products;
        }
      }
    }
  }
};
</script>
