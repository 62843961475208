<template>
  <div class="product-list-grid">
    <ProductFilters
      v-if="!hideFilters"
      :promoFilter="promoFilter"
      :newFilter="newFilter"
      :sortFilter="sortFilter"
      :facets="facets"
      :facetsFilter="facetsFilter"
      :filteredProductsCount="filteredProductsCount"
      :hidePromoFilter="hidePromoFilter"
      :hideFilterString="hideFilterString"
      :hideSort="hideSort"
      @handlePromoFilter="handlePromoFilter"
      @handleNewFilter="handleNewFilter"
      @handleFacetFilter="handleFacetFilter"
      @handleFacetFilterMobile="handleFacetFilterMobile"
      @handleSortFilter="handleSortFilter"
      :totItems="totItems"
    />
    <template v-if="totItems > 0">
      <div style="max-height:100%">
        <ProductGrid
          :key="key"
          :productList="products"
          :analiticsIndexPosition="(pager.selPage - 1) * pager.itemsPerPage"
          :virtualScrollViewport="virtualScrollViewport"
          @selectProduct="productClicked"
        />
      </div>

      <v-pagination
        v-if="
          (!loading || !virtualScrollViewport) &&
            pager &&
            pager.totPages > 1 &&
            !showLoadMoreItemBtn
        "
        :value="calcualtedPaginationPage"
        :length="calcualtedTotPages"
        :totalVisible="$vuetify.breakpoint.xs ? 6 : 7"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
        class="py-6"
        prev-icon="$chevronLeft"
        next-icon="$chevronRight"
        color="primary"
        circle
      ></v-pagination>
      <v-btn
        v-intersect.quiet="onIntersect"
        class="mt-4"
        block
        depressed
        x-large
        @click="loadMore"
        v-else-if="
          !loading &&
            virtualScrollViewport &&
            hasMoreItems &&
            showLoadMoreItemBtn
        "
      >
        <template>{{ $t("products.showMore") }}</template>
      </v-btn>
      <v-btn
        class="mt-4"
        v-else-if="loading"
        depressed
        block
        loading
        x-large
      ></v-btn>

      <!-- @next="fetchProducts"
        @prev="fetchProducts"
        @input="fetchProducts" -->
    </template>
    <div v-else-if="loading" class="product-list-grid-skeleton">
      <v-row>
        <v-col
          v-for="index in 12"
          :key="index"
          cols="12"
          sm="6"
          md="6"
          lg="3"
          xl="4"
        >
          <v-skeleton-loader
            type="image, list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <h2 class="py-8 default--text text-center" v-else-if="promoFilter">
      Nessun prodotto in promozione
    </h2>
    <h2 class="py-8 default--text text-center" v-else-if="newFilter">
      Nessuna novità qui
    </h2>
    <h2 class="py-8 default--text text-center" v-else>
      Nessun prodotto trovato
    </h2>
  </div>
</template>
<script>
import ProductFilters from "@/components/product/filters/ProductFilters.vue";
import ProductGrid from "./ProductGrid.vue";
import AnalyticsService from "~/service/analyticsService";
import ProductService from "~/service/productService";

import { mapGetters } from "vuex";

export default {
  name: "ProductList",
  props: {
    parentCategoryId: { type: Number, required: false },
    categoryId: { type: Number, required: false },
    query: { type: String, required: false },
    barcode: { type: String, required: false },
    mode: { type: String, required: false },
    hideFilters: { type: Boolean, default: false },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false },
    pageSize: { type: Number, required: false },
    virtualPageSize: { type: Number, default: 1 },
    virtualScrollViewport: { type: Boolean, default: false }
  },
  components: {
    ProductFilters,
    ProductGrid
  },
  jsonld() {
    if (this.products && this.products.products) {
      var items = this.products.products.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        url: process.env.VUE_APP_EBSN_URL + "/" + item.slug
      }));
      return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: items
      };
    }
  },
  data() {
    return {
      products: { products: [] },
      facets: [],
      pager: {},
      page: 1,
      busy: false,
      loading: false,
      filteredProductsCount: 0,
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter",
      sortFilter: "category/sortFilter",
      pageFilter: "category/pageFilter"
    }),
    calcualtedPaginationPage() {
      // return actual page when virtual scrolling, virtual scroll is when many pages are join in a real page
      const calculatedPage = this.pageFilter / this.virtualPageSize;
      return calculatedPage % 1 !== 0
        ? Math.floor(calculatedPage + 1)
        : Math.floor(calculatedPage);
    },
    calcualtedTotPages() {
      // return total pages when virtual scrolling
      if (this.pager?.totPages) {
        if (this.pager.totPages % this.virtualPageSize !== 0) {
          return Math.floor(this.pager.totPages / this.virtualPageSize) + 1;
        } else {
          return Math.floor(this.pager.totPages / this.virtualPageSize);
        }
      } else {
        return 0;
      }
    },
    showLoadMoreItemBtn() {
      return (
        this.virtualScrollViewport &&
        this.virtualPageSize > 1 &&
        this.pageFilter % this.virtualPageSize !== 0 &&
        this.pager?.totPages &&
        this.pageFilter < this.pager.totPages
      );
    },
    queryLabel() {
      return this.barcode ? this.barcode : this.query;
    },
    currentRouteName() {
      return this.$route.name;
    },
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    },
    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$store.dispatch("category/setFilterSort", sort);
      this.fetchProducts();
    },
    handlePromoFilter(promo) {
      this.$store.dispatch("category/setFilterPromo", promo);
      this.fetchProducts();
    },
    handleNewFilter(onlyNew) {
      this.$store.dispatch("category/setFilterNew", onlyNew);
      this.fetchProducts();
    },
    handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      this.fetchProducts();
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });
      this.fetchProducts();
    },
    handlePageFilter(page) {
      var _this = this;
      let calculatePageFilter =
        page * this.virtualPageSize - (this.virtualPageSize - 1);
      this.$store.dispatch("category/setFilterPage", calculatePageFilter);
      //await di this.fetchProduts
      this.fetchProducts();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },

    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchProducts(true);
      }
    },
    async fetchProducts(append) {
      this.loading = true;
      if (this.query && this.query.match(/^\s?(\d{13}|\d{8})\s?$/g)) {
        this.barcode = this.query;
        this.query = undefined;
      }
      let response = await ProductService.search(
        {
          parent_category_id: this.parentCategoryId,
          category_id: this.categoryId || null,
          q: this.query,
          barcode: this.barcode,
          page: this.pageFilter,
          page_size:
            this.pageSize || process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: this.sortFilter,
          promo: this.promoFilter,
          force: this.promoFilter || this.newFilter ? true : false,
          new_product: this.newFilter
        },
        this.facetsFilter
      );

      // Se ho letto il barcode e mi torna un Prodotto, navigo diretto al Dettaglio
      if (this.barcode && response.products.length > 0) {
        this.$router.push({
          name: "Product",
          params: { slug: response.products[0].slug }
        });
      } else if (append) {
        this.products = {
          append: true,
          products: response.products
        };
      } else {
        this.products = {
          append: false,
          products: response.products
        };
        // key only if not append, because was reloading all product when infinite scroll
        this.key++;
      }

      this.pager = response.page;
      this.facets = response.facets;

      this.$emit("productsCount", this.pager.totItems);
      this.filteredProductsCount = response.page.totItems;
      if (this.pager.searchedText) {
        this.$emit("searchedText", this.pager.searchedText);
      }
      this.loading = false;
      AnalyticsService.viewProducts(
        response.products,
        this.itemListName || "ProductListGrid",
        this.pageFilter,
        this.query
      );
    },
    productClicked(payload) {
      AnalyticsService.clickProduct(
        payload.product,
        this.itemListName || "ProductListGrid",
        payload.index
      );
    },
    doFilterChanged() {
      this.fetchProducts();
    }
  },
  async mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      let page = this.pageFilter;
      for (let i = 1; i <= page; i++) {
        this.$store.dispatch("category/setFilterPage", i);
        await this.fetchProducts(true);
      }
    } else {
      this.fetchProducts();
    }
    global.EventBus.$on("filterChanged", this.doFilterChanged);
  },
  beforeDestroy() {
    global.EventBus.$off("filterChanged");
  }
};
</script>

<!-- 
  TODO: 
  jsonld
  filterChange
  desktop
-->
